<template>
	<v-main class="admin-default-view-main primary-background">
		<v-container class="h-100 main-container background-transparent">
			<router-view :key="$route.path" />
		</v-container>
	</v-main>
</template>

<script>
export default {
	name: "AdminDefaultView"
};
</script>

<style lang="scss">
.v-main {
	background-color: #fff !important;
	.main-container {
		max-width: 100% !important;
	}
}
</style>
